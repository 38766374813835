import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ApiService } from './service/api/api.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  showStatusAnalysis = false

  constructor(
    private api: ApiService,
    private nav: NavController,
    private router: Router
  ) {
 
    this.checkAuthenAccessToken()

    window.addEventListener('message', res => {
      // console.log(res.data);
      if (res.data['type'] == 'analysis_agency') {
        if (res['data']['status'] == 'show') {
          this.showStatusAnalysis = true
        } else if (res['data']['status'] == 'hide') {
          this.showStatusAnalysis = false
        }
      }
      
    })
  }

  async checkAuthenAccessToken() {
    let url = decodeURIComponent(window.location.href)
    console.log(url)
 
    if (String(url).includes('reset-password')) {
      return
    }
    if (localStorage.getItem("access_token")) {
      await this.api.getUserData().then( res => {
        if (res['data']['code'] == 200) {
          // this.checkRouterToFirstPage()
        } else {
          alert("Token หมดอายุ")
          this.nav.navigateBack('/sign-in')
        }
      }).catch( () => {
        alert("Token หมดอายุ")
        this.nav.navigateBack('/sign-in')
      })
    } else {
      this.nav.navigateBack('/sign-in')
    }

 
  }

  checkRouterToFirstPage() {
    let _url = window.location.href
    let url = decodeURIComponent(_url)
    let page = url.split("#/")[1]
    if (page.includes("?")) {
      page = page.split("?")[0]
    }
    if (page.includes("&")) {
      page = page.split("&")[0]
    }

    let toLoginPageList = ["login-main", "login", "register", "select-type-service", "private-type-main", "factory-place-address"]
    if (toLoginPageList.includes(page)) {

      let user_type = localStorage.getItem("user_type")
      if (user_type == "agency") { 
        this.router.navigate(['/human-resource-first-page'])
      } else if (user_type == "hospital") {
        this.router.navigate(['/government-hospital-service-select'])
      }
    }

  }
}
